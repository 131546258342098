import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	//Slick Slider
	$('.slider-wrap').slick({
	  dots: false,
	  infinite: true,
	  speed: 400,
	  slidesToShow: 3,
	  slidesToScroll: 3,
	  prevArrow: '.arrow-prev',
	  nextArrow: '.arrow-next',
	  responsive: [
	    {
	      breakpoint: 1199,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2,
	        infinite: true
	      }
	    },
	    {
	      breakpoint: 767,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	    // You can unslick at a given breakpoint now by adding:
	    // settings: "unslick"
	    // instead of a settings object
	  ]
	});

	$('.slick-slide').matchHeight();

	// Meer Weten button
  
    $(".lees-meer-cta").click(function(){
    	$(this).css({"opacity": "0", "pointer-events": "none"});
        $(".tekst-part-4").fadeIn(500).addClass('activated');
    });
	
});